import React, { Component } from 'react'

class OnClickActiveArea extends Component {
    constructor(props) {
        super(props);
        this.removeClasses = this.removeClasses.bind(this);
    }

    removeClasses() {
        var activeAreas = document.getElementsByClassName('activeArea');
        for (var i = 0; i<activeAreas.length; i++) {
            activeAreas[i].classList.remove('activeArea')
        }
    };

    render() {
        return (
            <div onClick={this.removeClasses} onKeyPress={this.removeClasses} role="presentation">
                {this.props.children}
            </div>
        );

    }
}

export default OnClickActiveArea
